<template>
  <div class="product-description mb-8" v-if="tabs.length > 0">
    <v-container class="pt-0">
      <div v-if="$vuetify.breakpoint.smAndUp">
        <v-tabs
          v-model="selectedTab"
          slider-size="0"
          :show-arrows="$vuetify.breakpoint.xs"
          grow
        >
          <v-tab
            v-for="(tab, idx) in tabs"
            :key="idx"
            class="text-body-2 text-uppercase"
          >
            {{ tab.title }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab">
          <v-tab-item
            transition="none"
            v-for="tab in tabs"
            :key="tab.title"
            :class="tab.cssClass"
          >
            <div
              v-for="item in tab.items"
              :key="item.title"
              :class="item.cssClass"
            >
              <div
                class="text-body-2 text-uppercase font-weight-bold mb-1 mt-3"
                v-if="item.title"
              >
                {{ item.title }}
              </div>
              <div
                class="text-body-2 content default--text"
                v-for="path in item.paths"
                :key="path.key"
              >
                <component
                  :is="path.template || 'DefaultTpl'"
                  :content="path.content"
                  :append="path.append"
                  class="component pb-4"
                />
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <v-expansion-panels v-else accordion flat tile v-model="panel">
        <v-expansion-panel
          v-for="tab in tabs"
          :key="tab.title"
          :class="tab.cssClass"
          :aria-controls="'panel-content-' + tab.title"
          role="button"
        >
          <v-expansion-panel-header
            class="pa-3 px-sm-3 primary--text font-weight-bold"
            :aria-controls="'panel-content-' + tab.title"
            role="button"
            :aria-expanded="panel.includes(tab.title) ? 'true' : 'false'"
          >
            <template v-slot:actions>
              <v-icon>$chevronDown</v-icon>
            </template>
            <div class="text-body-1 font-weight-bold" v-html="tab.title" />
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mt-2">
            <div
              v-for="item in tab.items"
              :key="item.title"
              class="pb-4"
              :class="item.cssClass"
            >
              <h4
                v-if="item.title"
                class="mb-1 description-header font-weight-bold"
              >
                {{ item.title }}
              </h4>
              <div
                v-for="path in item.paths"
                :key="path.key"
                class="description-content"
              >
                <component
                  :is="path.template || 'DefaultTpl'"
                  :content="path.content"
                  :append="path.append"
                />
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
import get from "lodash/get";
import tabsConfig from "/public/product/descriptionTabsConfig.json";
import kebabCase from "lodash/kebabCase";
import {
  DefaultTpl,
  LabelsTpl,
  ItemsMapTpl,
  TableTpl,
  NameAndValueTpl,
  ValueTpl,
  WarningsTpl,
  ListTpl
} from "./templates";
export default {
  name: "ProductDescription",
  /* eslint-disable vue/no-unused-components */
  components: {
    DefaultTpl,
    LabelsTpl,
    ItemsMapTpl,
    TableTpl,
    NameAndValueTpl,
    ValueTpl,
    WarningsTpl,
    ListTpl
  },
  data() {
    return {
      selectedTab: null,
      productDescriptionTabs: tabsConfig,
      panel: []
    };
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    productMetadata: {
      type: Object
    },
    ingredientList: {
      type: Object
    }
  },
  computed: {
    tabs() {
      let tabs = [];
      this.productDescriptionTabs.forEach(tabMeta => {
        let items = [];
        tabMeta.items.forEach(parMeta => {
          let paths = [];
          parMeta.paths.forEach(pathMeta => {
            let content = get(this.product, pathMeta.data || pathMeta);
            let append = pathMeta.append;
            // check if content is empty
            if (content && content !== "<p></p>") {
              // check if table has empty cells
              if (pathMeta.template === "TableTpl") {
                let cells = JSON.parse(content).cells;
                let hasContent = cells.find(c => {
                  return c !== null;
                });
                if (!hasContent) {
                  return;
                }
              }
              if (pathMeta.template === "ValueTpl") {
                let value = JSON.parse(content).value;
                if (!value) {
                  return;
                }
              }
              paths.push({
                key: pathMeta.data || pathMeta,
                content: content,
                template: pathMeta.template,
                append: append
              });
            }
          });
          if (paths.length > 0) {
            items.push({
              title: parMeta.title,
              paths: paths,
              cssClass: get(parMeta, "cssClass", kebabCase(parMeta.title))
            });
          }
        });
        if (items.length > 0) {
          tabs.push({
            title: tabMeta.title,
            items: items,
            cssClass: get(tabMeta, "cssClass", kebabCase(tabMeta.title))
          });
        }
      });

      // additional text at Ingredients tab end
      // let ingredientsTab = tabs.find(t => {
      //   return t.title === "Ingredienti e valori nutrizionali";
      // });
      // if (ingredientsTab) {
      //   ingredientsTab.items.push({
      //     paths: [
      //       { content: this.$t("productDescription.ingredientsAdditionalInfo") }
      //     ]
      //   });
      // }
      return tabs;
    }
  }
};
</script>

<style lang="scss">
.product-description {
  background-color: var(--v-grey-base);
  .tag-allergeni {
    font-weight: bold;
  }
  .ingredients {
    p {
      display: inline;
      &:after {
        content: ", ";
      }
      &:last-child:after {
        content: ".";
      }
    }
  }
  .ALLERGEN_CONTAINS {
    li {
      span {
        display: block;
        &:first-child {
          color: #333;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
  }
  .producer {
    br {
      break-inside: avoid;
      &:after {
        content: ", ";
      }
    }
  }
  .origin,
  .brand,
  .conservation-type {
    ul {
      li {
        span:first-child {
          text-transform: uppercase;
          &:after {
            content: ":";
          }
        }
      }
    }
  }
  .description {
    &:last-child {
      border-bottom: none;
      .component {
        padding-bottom: 0 !important;
      }
    }
  }
  table {
    border-collapse: collapse;
  }
  table,
  tr,
  td,
  th {
    border-top: 1px solid var(--v-default-base);
    border-bottom: 1px solid var(--v-default-base);
  }
  td,
  th {
    padding: 8px 5px;
  }
  th {
    background-color: var(--v-grey-lighten1);
  }
  td:first-child,
  td.nutritional-header {
    font-weight: 700;
    font-size: 14px;
  }
  .v-tabs {
    background-color: transparent;
    position: relative;
    top: 1px;
    z-index: 1;
    .v-slide-group__wrapper {
      margin-top: -1px;
    }
    .v-tabs-bar {
      background-color: transparent;
    }
    .v-item-group {
      height: 60px;
    }
    .v-tab {
      color: var(--v-default-base);
      background-color: transparent;
      border-radius: 0 0 8px 8px;
      font-weight: bold;
    }
    .content {
      line-height: 22px !important;
      ul {
        padding-left: 0 !important;
      }
      li {
        list-style: none !important;
      }
    }
    .v-tab--active {
      background-color: $white;
      &:hover::before {
        opacity: 0 !important;
      }
    }
  }
  .v-tabs-items {
    background-color: var(--v-grey-base);
    padding: 20px;
    .nutritional-info,
    .ingredients,
    .description,
    .producer_info,
    .other,
    .conservation_info,
    .package_info,
    .preparation_info {
      margin-bottom: 10px;
      .content {
        line-height: 22px;
      }
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-expansion-panels {
      background-color: transparent;
      .v-expansion-panel--active > .v-expansion-panel-header {
        min-height: 48px;
      }
      .v-expansion-panel-header {
        border-bottom: 1px solid $border-color;
      }
      .v-expansion-panel {
        background-color: transparent;
      }
      .v-expansion-panel-content {
        .v-expansion-panel-content__wrap {
          padding: 0 12px 16px;
          .description-header {
            font-size: 16px;
          }
          .description-content {
            font-size: 14px;
          }
        }
      }
      .v-expansion-panel {
        margin-bottom: 1px;
      }
    }
  }
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .product-description {
    background-color: $white;
  }
}
</style>
