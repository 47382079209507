<template>
  <v-container class="not-found">
    <v-row no-gutters style="height: 100%;">
      <v-col :align-self="'center'" justify-center class="text-center">
        <div class="text-vertical-center text-center secondary--text">
          <v-img
            alt="404"
            style="margin: 0 auto;"
            src="/img_layout/404.png"
            class="mb-12"
            height="555"
            contain
          />
          <v-btn color="primary" depressed :to="{ name: 'Home' }">
            TORNA ALLA HOMEPAGE
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "NotFound",
  mounted() {
    if (this.isCordova) {
      this.$router.push({
        name: "Home",
        path: "/"
      });
    }
  }
};
</script>
<style lang="scss">
.not-found {
  .text-vertical-center {
    text-align: center;
    vertical-align: middle;
    width: 100%;
  }
  .text-vertical-center h1 {
    margin: 0;
    padding: 0;
    color: $primary;
    margin: 0;
    padding: 18px 0;
    font: 900 35px;
  }
  .text-vertical-center h3 {
    margin: 0;
    padding: 5px 0;
    font: 800 22px;
  }
  .text-vertical-center h4 {
    margin: 0;
    padding: 0 0 30px 0;
    font: 400 20px;
  }
}
</style>
