<template>
  <div v-if="rendered">{{ rendered.value }}</div>
</template>
<script>
export default {
  props: {
    content: { type: String, required: true }
  },
  data() {
    return { rendered: {} };
  },
  created() {
    this.rendered = JSON.parse(this.content);
  }
};
</script>
