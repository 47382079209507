<template>
  <div v-html="text" />
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
export default {
  name: "DefaultTpl",
  props: {
    content: { type: String, required: true },
    append: { type: String }
  },
  data() {
    return {
      text: ""
    };
  },
  created() {
    if (this.content === "Sì") {
      return;
    }
    // es.: numero di porzioni
    if (this.append) {
      let appendString = isNaN(parseInt(this.content))
        ? this.content
        : parseInt(this.content);
      this.text = this.$tc(this.append, appendString);
    } else {
      this.text = cloneDeep(this.content);
    }
  }
};
</script>
