<template>
  <div v-if="rendered.length">
    <div v-for="item in rendered" v-html="item.label" :key="item.key" />
  </div>
</template>
<script>
export default {
  props: {
    content: { type: String, required: true }
  },
  data() {
    return { rendered: [] };
  },
  created() {
    this.rendered = JSON.parse(this.content);
  }
};
</script>
